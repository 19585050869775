import React from "react"
import LayoutCourse from "../../../../../layouts/course"
import Seo from "../../../../../components/seo"
import Stack from "../../../../../components/stack"
import CourseFooterNext from "../../../../../components/course-footer-next"
import {
  getCourseNavigation,
  useCourseStore,
} from "../../../../../store/courses"
import Grid from "../../../../../components/grid"
import Text from "../../../../../components/text"
import Heading from "../../../../../components/heading"
import Paragraph from "../../../../../components/paragraph"

const Page = () => {
  const { getAnswer } = useCourseStore()
  const navigation = getCourseNavigation({ courseId: "haelfte-des-lebens" })
  const taskProps = {
    courseId: "haelfte-des-lebens",
    chapterId: "01-zwei-haelften",
    taskId: "satzbau",
  }
  const answer = getAnswer(taskProps)

  return (
    <LayoutCourse
      navigation={navigation}
      footer={
        <CourseFooterNext
          title="Alles klar soweit?"
          label="Weiter"
          to="/kurse/haelfte-des-lebens/01-zwei-haelften/saetze-ausmisten"
        />
      }
    >
      <Seo title="Satzenden finden" />
      <Stack>
        <Heading as="h2" level={2}>
          Satzenden finden
        </Heading>
        <Grid columns={[1, 2]}>
          <Stack space={6}>
            <Text sans bold>
              Deine Antwort
            </Text>
            <Paragraph as="div" size={[3, 3, 4]}>
              <div dangerouslySetInnerHTML={{ __html: answer }}></div>
            </Paragraph>
          </Stack>
          <Stack space={6}>
            <Text sans bold>
              Lösung
            </Text>
            <Paragraph as="div" size={[3, 3, 4]}>
              <Stack space={6}>
                <p>
                  Mit gelben Birnen hänget Und voll mit wilden Rosen Das Land in
                  den See, Ihr holden Schwäne, Und trunken von Küssen Tunkt ihr
                  das Haupt Ins heilignüchterne Wasser.
                </p>
                <p>
                  Weh mir, wo nehm’ ich, wenn Es Winter ist, die Blumen, und wo
                  Den Sonnenschein, Und Schatten der Erde?
                </p>
                <p>
                  Die Mauern stehn Sprachlos und kalt, im Winde Klirren die
                  Fahnen.
                </p>
              </Stack>
            </Paragraph>
          </Stack>
        </Grid>
        <Paragraph>
          Die 1. Strophe des Gedichts besteht aus nur einem einzigen Satz, der
          alles harmonisch mitenander verbindet und ineinander fließen lässt. In
          der Mitte des Satzes, die exakt in der Mitte der 1. Strophe verortet
          ist, steht der eingeschobene Ausruf: »Ihr holden Schwäne«.
        </Paragraph>
        <Paragraph>
          Die 2. Strophe dagegen zerfällt in zwei Sätze: Eine Frage, die durch
          mehrere Einschübe unterbrochen wird, und einen nachfolgenden Satz, der
          aus zwei Teilen besteht.
        </Paragraph>
        <Paragraph>
          Die Versgrenzen, der Satzbau und der sich daraus ergebende Rhythmus
          sorgen somit dafür, dass zwischen den beiden Strophen ein Bruch
          spürbar wird. Das Gedicht zerfällt in zwei Hälften.
        </Paragraph>
      </Stack>
    </LayoutCourse>
  )
}

export default Page
